(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("MaterialTxnDialogController", MaterialTxnDialogController);

    MaterialTxnDialogController.$inject = ['$state', 'Inventory', 'TrMaterial', '$scope', 'ParseLinks', 'AlertService',
        'pagingParams', '$timeout', '$window', 'entity', 'entityId'];

    function MaterialTxnDialogController($state, Inventory, TrMaterial,
        $scope,
        ParseLinks,
        AlertService,
        pagingParams,
        $timeout,
        $window,
        entity,
        entityId
    ) {
        var vm = this;
        //vm.transactionType = ["PLANNED","REQUESTED","RECEIVED","UTILIZED","RECONCILED"]
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        var objName = $state.params.entityName
        vm.isSaving = true
        vm.selectedMaterial = []
        switch (objName) {
            case "taskId": vm.transactionType = ["REQUESTED"]
                break;

            case "sectionId": vm.transactionType = ["REQUESTED"]
                break;

            default: console.log("other")
        }


        TrMaterial.query(
            {
                page: 0,
                size: 100,
                sort: sort(),
            },
            onSuccess,
            onError
        );

        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                //  result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            vm.materials = [];
            data.forEach(function (value) {
                if (value.zone) {
                    value.type = "MASTER";
                } else {
                    value.type = "MINI";
                }
                vm.materials.push(value);
            });
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        if (entity) {
            Inventory.getMaterialTxn(entityId, entity, function (response) {
                vm.materialTxn = response
                if (vm.materialTxn[0].id) {
                    vm.materialTxn[0].transactionDate = new Date(vm.materialTxn[0].transactionDate);
                }
            })
            vm.isShow = true
            vm.isSaving = false
        } else {
            vm.materialTxn = []
            //vm.materialTxn = [{ }]
            //vm.materialTxn[0][objName] = $state.params.entityId 
            //
        }

        /* var today = new Date();
        var todayFormatted = today.toISOString().split("T")[0];
        document.getElementById("dateInput").max = todayFormatted; */






        vm.save = function () {
            //vm.materialTxn.materialId = parseInt(vm.materialTxn.materialId)

            /*  if(vm.materialTxn.txnDate){
                 vm.materialTxn.transactionDate = epoch(vm.materialTxn.txnDate)
             }  */

            if (vm.materialTxn[0].id) {
                vm.dateChanged(0)
                Inventory.putMaterialTxn(vm.materialTxn[0], function () {
                    $window.history.back();
                })
            } else {
                vm.materialTxn.forEach(function (each) {
                    each.transactionDate = epoch(each.transactionDate)
                })
                Inventory.v1postBulkMaterialTxn(vm.materialTxn, function () {
                    $window.history.back();
                })
            }

        }

        var today = new Date();
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.options = {
            maxDate: today
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }



        function epoch(date) {
            return Date.parse(date)
        }

        $scope.addDetails = function () {
            //vm.materialTxn.push({ objName : $state.params.entityId})
        }

        vm.dateChanged = function (index) {
            if (vm.materialTxn[index].transactionDate) {
                vm.materialTxn[index].transactionDate = epoch(vm.materialTxn[index].transactionDate)
            }
        }

        $scope.removeDetails = function (id) {
            vm.materialTxn.splice(id, 1);

            if (!vm.materialTxn.length) {
                vm.isSaving = true
            }
        };

        $('.select2').select2();

        vm.onChangeUom = function (id, index) {

            var temp = vm.materials.find(function (eachElement) {
                return eachElement.id == id
            })

            vm.materialTxn[index].unitOfMeasurement = temp.unitOfMeasurement
        }

        $scope.type = {
            materials: {
                search: "Search",
                nothingSelected: "Materials",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            }
        };

        vm.onSelectedMaterial = function () {
            if (vm.selectedMaterial.length && vm.selectTransactionType && vm.selectDate) {
                //vm.materialTxn = []
                vm.selectedMaterial.forEach(function (each, index) {
                    var exists = vm.materialTxn.some(function (element) {
                        element.transactionDate = vm.selectDate
                        element.transactionType = vm.selectTransactionType
                        return (element.materialId === each.id)
                    });

                    var temp = vm.materials.find(function (eachElement) {
                        return eachElement.id == each.id
                    })

                    if (!exists) {
                        //     vm.materialTxn.push({
                        //         "materialId": each.id,
                        //         "unitOfMeasurement": temp.unitOfMeasurement,
                        //         "noOfUnits": null,
                        //         "transactionDate": vm.selectDate,
                        //         "transactionType": vm.selectTransactionType,
                        //         "invoiceNumber": null,
                        //         "remarks": null
                        //     })

                        //  vm.materialTxn[index][objName] = $state.params.entityId

                        // }  

                        vm.materialTxn.push({
                            "materialId": each.id,
                            "unitOfMeasurement": temp.unitOfMeasurement,
                            "noOfUnits": null,
                            "transactionDate": vm.selectDate,
                            "transactionType": vm.selectTransactionType,
                            "invoiceNumber": null,
                            "remarks": null,
                            'sectionId': $state.params.sectionId
                        })

                        vm.materialTxn[index][objName] = parseInt($state.params.entityId)

                    }
                })

                vm.isSaving = false
                //$scope.$apply()
            }
        }
    }
})();
