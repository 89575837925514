(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrRowDialogController", TrRowDialogController);

    TrRowDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrRow",
        "Configs",
        "route",
        "postRoutePoints",
        "AlertService",
        "$window",
        "TrRouteListIdName",
        "RoutePathById",
        "AllRowTypes",
        "AllCustomRowTypeAttribute",
        "$rootScope"
    ];

    function TrRowDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrRow,
        Configs,
        route,
        postRoutePoints,
        AlertService,
        $window,
        TrRouteListIdName,
        RoutePathById,
        AllRowTypes,
        AllCustomRowTypeAttribute,
        $rootScope
    ) {
        var vm = this;
        vm.trRow = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trRow);
        vm.rowType = entity.rowType;
        var markers = []
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var labelFrom, labelTo
        labelFrom = 'From Row'
        labelTo = 'To Row'
        vm.routeName = route
        vm.selectRoute = selectRoute;
        vm.selectAttributes = selectedRowTypeAttributes;
        vm.isSetUpdate = false;
        var coordinateList = [];
        var chainageList = [];
        var markerList = [];
        vm.rowType = entity.rowType;

        if ($state.params.routeId) {
            vm.trRow.routeId = parseInt($state.params.routeId, 10);
        }

        if (!vm.trRow.id) {
            //vm.trRow.status = "NEW"
            vm.trRow.phase = "PLANNING"
        }
        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();
        var locations = {};
        var locationsEnd = {};


        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };
            TrRouteListIdName.get(function (response) {
                vm.routes = response;
            });


            var rowTypeAttributeValuesList =
                vm.trRow.rowTypeAttributeValues;

            try {
                for (var index in vm.trRow.rowType.rowTypeAttribute) {
                    if (
                        !checkIfRowTypeExists(
                            vm.trRow.rowType.rowTypeAttribute[index],
                            vm.trRow.rowTypeAttributeValues
                        )
                    ) {
                        var rowTypeAttribute1 =
                            vm.trRow.rowType.rowTypeAttribute[index];
                        rowTypeAttributeValuesList.push({
                            rowTypeAttribute: rowTypeAttribute1,
                            attributeValue:
                                vm.trRow.rowType.rowTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfRowTypeExists(rowType, rowTypeAttrVal) {
                var hasRowType = false;
                for (var i = 0; i < rowTypeAttrVal.length; i++) {
                    var name = rowTypeAttrVal[i].rowTypeAttribute.name;
                    if (name == rowType.name) {
                        hasRowType = true;
                        break;
                    }
                }
                return hasRowType;
            }
            AllRowTypes.getAll(function (response) {
                vm.rowTypes = response;
            });

            AllCustomRowTypeAttribute.getAll(function (response) {
                //vm.customRowTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customRowTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customRowTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trRow.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });

        }
        // $timeout(function () {
        //     angular.element(".form-group:eq(1)>input").focus();
        // });

        function selectedRowTypeAttributes() {
            vm.showFilter = true;
            if (vm.rowType) {
                vm.trRow.rowType = vm.rowType;
                if (
                    entity_old.rowType &&
                    vm.rowType.id === entity_old.rowType.id
                ) {
                    vm.trRow.rowTypeAttributeValues = [];
                    vm.trRow.rowTypeAttributeValues =
                        entity_old.rowTypeAttributeValues;
                } else {
                    vm.trRow.rowTypeAttributeValues = [];
                    $.each(
                        vm.rowType.rowTypeAttribute,
                        function (key, value) {
                            vm.trRow.rowTypeAttributeValues.push({
                                rowTypeAttribute:
                                    vm.rowType.rowTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function selectRoute() {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });
            coordinateList = [];
            chainageList = [];
            markerList = [];
            locations.map
            initMap();
            RoutePathById.get(vm.trRow.routeId, function (response) {

                var groupedSessions = {};
                if (response.COORDINATE) {
                    response.COORDINATE.forEach(function (value) {
                        if (!groupedSessions[value.sessionNumber]) {
                            groupedSessions[value.sessionNumber] = { coordinates: [], markers: [], chainages: [] };
                        }
                        groupedSessions[value.sessionNumber].coordinates.push({ lat: value.latitude, lng: value.longitude });
                    });
                }
                if (response.CHAINAGE) {
                    response.CHAINAGE.forEach(function (value) {
                        if (!groupedSessions[value.sessionNumber]) {
                            groupedSessions[value.sessionNumber] = { coordinates: [], markers: [], chainages: [] };
                        }
                        groupedSessions[value.sessionNumber].chainages.push({ lat: value.latitude, lng: value.longitude });
                    });
                }
                if (response.MARKER) {
                    response.MARKER.forEach(function (value) {
                        if (!groupedSessions[value.sessionNumber]) {
                            groupedSessions[value.sessionNumber] = { coordinates: [], markers: [], chainages: [] };
                        }
                        groupedSessions[value.sessionNumber].markers.push({ lat: value.latitude, lng: value.longitude });
                    });
                }
                Object.keys(groupedSessions).forEach(function (sessionNumber) {
                    var sessionData = groupedSessions[sessionNumber];
                    if (sessionData.coordinates.length) {
                        var polyline = new google.maps.Polyline({
                            path: sessionData.coordinates,
                            geodesic: true,
                            strokeColor: '#FD7A24',
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                        });
                        polyline.setMap(locations.map);
                        addClick(polyline);
                        var bounds = new google.maps.LatLngBounds();
                        sessionData.coordinates.forEach(function (coord) {
                            bounds.extend(coord);
                        });
                        locations.map.fitBounds(bounds);
                    }
                    if (sessionData.chainages.length) {
                        createMarkers(sessionData.chainages, 'content/images/chainagePoints.png', locations.map);
                    }
                    if (sessionData.markers.length) {
                        createMarkers(sessionData.markers, 'content/images/markersPoint.png', locations.map);
                    }
                });
            });
        }

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }

        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                addClick(marker)
                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: markerText,
                }
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        function save() {
            if (markers.length == 2) {
                var payload
                markers.forEach(function (item) {
                    if (item.label.text == labelFrom || item.label.text == labelTo) {
                        if (item.label.text == labelFrom) {
                            vm.trRow.fromRoutePoint = {};
                            vm.trRow.fromRoutePoint.latitude = item.position.lat();
                            vm.trRow.fromRoutePoint.longitude = item.position.lng();
                        }
                        if (item.label.text == labelTo) {
                            vm.trRow.toRoutePoint = {};
                            vm.trRow.toRoutePoint.latitude = item.position.lat();
                            vm.trRow.toRoutePoint.longitude = item.position.lng();
                            if (vm.trRow.id != null) {
                                TrRow.update(vm.trRow, onSaveSuccess, onSaveError);
                            } else {
                                TrRow.save(vm.trRow, onSaveSuccess, onSaveError);
                            }

                        }
                    }
                })
            } else {
                alert("Row From or To Points are Empty")
            }

        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:rowUpdate", result);
            //vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $window.history.back();
            }
        }

        function onSaveError() {
            //vm.isSaving = false;
        }

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });

        //start
        initMap();
        function initMap() {
            locations.map = new google.maps.Map(
                document.getElementById("map_canvasStart"),
                {
                    center: {
                        lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                        lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                    },
                    zoom: 13,
                    fullscreenControl: true,
                    styles: mapStyles(),
                }
            );
        }


        if (vm.trRow.id != null) {
            selectRoute();
        }
        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];

            if (vm.trRow.id) {
                vm.isSetUpdate = true
            }

        }

        if (route && route.routePoints != null && route.routePoints.length) {
            route.routePoints.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });

            route.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE") {
                    coordinateList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "MARKER") {
                    markerList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "CHAINAGE") {
                    chainageList.push({ lat: each.latitude, lng: each.longitude })
                }
            })

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                addClick(polyline)
            }

            if (markerList.length) {
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList, 'content/images/markersPoint.png', locations.map)
            }

            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
            }
        }


        //Functions

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                addClick(marker)
                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                }
            });

            // Add the marker to the markers array
            markers.push(marker);
        }


        if (vm.trRow.id && vm.trRow.fromRoutePoint && vm.trRow.toRoutePoint) {
            addMarker({ lat: vm.trRow.fromRoutePoint.latitude, lng: vm.trRow.fromRoutePoint.longitude })
            addMarker({ lat: vm.trRow.toRoutePoint.latitude, lng: vm.trRow.toRoutePoint.longitude })
            locations.map.setCenter({ lat: vm.trRow.fromRoutePoint.latitude, lng: vm.trRow.fromRoutePoint.longitude });
        }

        $('.select2').select2();
    }
})();
