angular.module('trakeyeApp').directive('materialTxnTab', [
  'SummaryPost', '$rootScope', '$window', 'ParseLinks', 'Geofence', 'Summary', 'MaterialByDates', 'MaterialDates', 'Inventory',
  function (SummaryPost, $rootScope, $window, ParseLinks, Geofence, Summary, MaterialByDates, MaterialDates, Inventory) {
    return {
      restrict: 'E',
      scope: {
        obj: '=',
      },
      templateUrl: 'app/components/View/materialTxnTab.html',
      link: function (scope) {

        scope.MATERIALTX_EDIT = $rootScope.Roles.MATERIALTX_EDIT;
        scope.entityId = scope.obj.id;
        scope.entityName = scope.obj.name;
        scope.sectionId = scope.obj.sectionId;

        scope.page = 1;
        scope.itemsPerPage = 10;
        scope.totalItems = 0;
        scope.queryCount = 0;

        scope.summaryPage = 1;
        scope.summaryItemsPerPage = 10;
        scope.summaryTotalItems = 0;
        scope.summaryQueryCount = 0;


        scope.txnPage = 1;
        scope.txnItemsPerPage = 10;
        scope.txnTotalItems = 0;
        scope.txnQueryCount = 0;

        scope.reverse = false;
        scope.mtrlTxListView = [];
        scope.mtrlTxSummary = [];
        scope.materialByDate = [];
        scope.materialTxnByDates = [];
        $('.select2').select2();

        SummaryPost.mtrlTx({ taskIds: [scope.entityId] }, function (response) {
          scope.mtrlTxSummary = response
        })
        function sort() {
          var result = [scope.predicate + ',' + (scope.reverse ? 'asc' : 'desc')];
          return result;
        }
        scope.transition = function () {
          loadAll()
        }
        //loadAll()
        function loadAll() {
          /* Geofence.query({
            page: scope.page,
            size: scope.itemsPerPage,
            sort: sort()
          }, onSuccess, onError); */

          function onSuccess(data, headers) {
            scope.links = ParseLinks.parse(headers('link'));
            scope.totalItems = headers('X-Total-Count');
            scope.queryCount = scope.totalItems;
            scope.mtrlTxListView = []
            data.forEach(function (value) {
              scope.mtrlTxListView.push(value);
              // console.log(data)
            });
            //vm.page = pagingParams.page;
          }

          function onError(error) {
            console.log(error)
          }
        }


        function loadMaterialTxnDate() {
          MaterialDates.query(
            {
              type: 'task',
              id: scope.entityId,
              page: scope.summaryPage - 1,
              size: scope.summaryItemsPerPage,
              sort: "last_modified_date,desc"
            },
            function (data, headers) {
              scope.links = ParseLinks.parse(headers('link'));
              scope.summaryTotalItems = headers('X-Total-Count');
              scope.summaryQueryCount = scope.summaryTotalItems;
              scope.materialByDate = data.map(function (item) {
                return {
                  timestamp: item[0],
                  total: item[1],
                  isExpand: false,
                };
              });
            }
          );
        }

        function loadMaterialTxnByDates(date) {
          MaterialByDates.query(
            {
              type: 'task',
              id: scope.entityId,
              date: date,
              page: scope.txnPage - 1,
              size: "last_modified_date,desc",
              sort: sort()
            }, onSuccess)
          function onSuccess(data, headers) {
            scope.links = ParseLinks.parse(headers('link'));
            scope.txnTotalItems = headers('X-Total-Count');
            scope.txnQueryCount = scope.txnTotalItems;
            scope.materialTxnByDates = data.map(function (item) {
              return {
                materialId: item[0] || "NA",
                timestamp: item[4] || "NA",
                materialType: item[1] || "NA",
                materialName: item[2] || "NA",
                transactionType: item[5] || "NA",
                mtrlTxNumber: item[6] || "NA",
                count: item[3] || "NA"
              };
            });
          };
        }

        scope.mtrlTxTransition = function () {
          loadAll();
        };

        scope.loadMaterialTxnDate = function () {
          loadMaterialTxnDate();
        };

        scope.loadMaterial = function (timestamp) {
          loadMaterialTxnByDates(timestamp);
        };

        scope.toggleExpand = function (selectedTask) {
          angular.forEach(scope.materialByDate, function (task) {
            if (task === selectedTask) {
              task.isExpand = !task.isExpand;
              if (task.isExpand) {
                scope.loadMaterial(task.timestamp);
              } else {
                scope.materialTxnByDates = [];
              }
            } else {
              task.isExpand = false;
            }
          });
        };

        loadAll();
        loadMaterialTxnDate();
        var today = new Date();
        today.setHours(23, 59, 59);
        scope.datePickerOpenStatus = {};
        scope.openCalendar = openCalendar;
        scope.options = {
          maxDate: today
        };
        function openCalendar(date) {
          scope.datePickerOpenStatus[date] = true;
        }



        function epoch(date) {
          return Date.parse(date)
        }
        scope.isModalOpen = false;
        scope.selectedTransaction = {};
        scope.openTransactionModal = function (transaction) {
          scope.selectedTransaction = angular.copy(transaction);
          scope.isModalOpen = true;
        };

        scope.saveTransaction = function () {
          var transactionData = {
            materialId: scope.selectedTransaction.materialId,
            unitOfMeasurement: scope.selectedTransaction.unitOfMeasurement,
            noOfUnits: scope.selectedTransaction.noOfUnits,
            transactionDate: epoch(scope.selectedTransaction.transactionDate),
            transactionType: scope.selectedTransaction.transactionType,
            mtrlTxNumber: scope.selectedTransaction.mtrlTxNumber,
            invoiceNumber: scope.selectedTransaction.invoiceNumber || null,
            remarks: scope.selectedTransaction.remarks || null,
            taskId: scope.entityId,
            sectionId: scope.obj.sectionId

          };
          Inventory.postBulkMaterialTxn([transactionData], function () {
            $window.history.back();
          }, function (error) {
            console.error('Error saving transaction:', error)
          });
          scope.isModalOpen = false;
        };

        scope.cancelTransaction = function () {
          scope.selectedTransaction = {};
          scope.isModalOpen = false;
        };

        scope.transactionType = ["RECEIVED", "UTILIZED", "RECONCILED"]
      },

    };

  }
]);
